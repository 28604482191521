import React, { useEffect } from 'react'
import Select from 'react-select'
import capitalize from 'lodash/capitalize'
import flatten from 'lodash/flatten'
import sortBy from 'lodash/sortBy'
import { Button, Col, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import RadioButton from '../common/RadioButton'
import { Option } from '../../lib/helpers'
import { QueryName } from '../../hasura/queryNames'
import { conditionsSelector, ConditionsState, updateConditionActiveLearningPriorityAction } from '../../hasura/slices/conditions'
import { consultationsSelector, ConsultationsState } from '../../hasura/slices/consultations'
import { machineLearningSelector, MachineLearningState, updateMLServerStatusAction } from '../../hasura/slices/machine-learning'
import { usersSelector, UsersState } from '../../hasura/slices/users'

const typeForPriority = (priority: number) => (priority === 1 ? 'Train' : 'Active Learning')
const priorityForType = (type: string) => (type === 'Train' ? 1 : 2)

export default function Training() {
  const dispatch = useDispatch()

  const { mlServerStatus, singleGpuMlServerStatus }: MachineLearningState = useSelector(machineLearningSelector)
  const { conditions }: ConsultationsState = useSelector(consultationsSelector)
  const { isQuerying }: ConditionsState = useSelector(conditionsSelector)
  const { accessToken }: UsersState = useSelector(usersSelector)

  const [displayScheduleTrainingModal, setDisplayScheduleTrainingModal] = React.useState(false)
  const [selectedCondition, setSelectedCondition] = React.useState<Option | null>(null)
  const [selectedSpecies, setSelectedSpecies] = React.useState<string | null>(null)
  const [selectedType, setSelectedType] = React.useState<string | null>(null)

  const scheduledConditions = flatten(
    conditions.map((condition) =>
      [
        { status: condition.active_learning_cat_priority, species: 'cat', condition },
        { status: condition.active_learning_dog_priority, species: 'dog', condition },
        { status: condition.active_learning_combined_priority, species: 'combined', condition },
      ].filter((condition) => condition.status > 0)
    )
  )

  const conditionOptions: Option[] = sortBy(
    conditions.map((condition) => ({
      value: condition.id,
      label: condition.display_name,
    })),
    'label'
  )

  const selectedConditionEntity = conditions.find((condition) => condition.id === selectedCondition?.value)

  useEffect(() => {
    if (displayScheduleTrainingModal) {
      setSelectedCondition(null)
      setSelectedSpecies(null)
      setSelectedType(null)
    }
  }, [displayScheduleTrainingModal])

  const scheduleTraining = async () => {
    const priority = priorityForType(selectedType!)
    await dispatch(updateConditionActiveLearningPriorityAction(accessToken, selectedCondition!.value, priority, selectedSpecies!))
    setDisplayScheduleTrainingModal(false)
  }

  const cancelTraining = async (conditionId: number, species: string) =>
    dispatch(updateConditionActiveLearningPriorityAction(accessToken, conditionId, 0, species))

  return (
    <Col sm="12">
      <Modal
        fade={false}
        centered
        autoFocus={false}
        isOpen={displayScheduleTrainingModal}
        toggle={() => setDisplayScheduleTrainingModal(false)}
      >
        <ModalHeader>Schedule Training</ModalHeader>

        <ModalBody className="p-4">
          <div className="mb-4">
            <h6>1. Condition</h6>
            <Select onChange={(option) => setSelectedCondition(option)} options={conditionOptions} value={selectedCondition} />
          </div>

          <div className="mb-4">
            <h6>2. Species</h6>
            {selectedCondition && selectedConditionEntity?.ml_config && (
              <div className="d-flex gap-15px">
                {['cat', 'dog', 'combined']
                  .filter((s) => selectedConditionEntity.ml_config['species'].includes(s))
                  .map((s, idx) => (
                    <RadioButton
                      key={idx}
                      checked={selectedSpecies === s}
                      onClick={() => setSelectedSpecies(s)}
                      label={capitalize(s)}
                    />
                  ))}
              </div>
            )}
          </div>

          <div>
            <h6>3. Type</h6>
            {selectedCondition && (
              <div className="d-flex gap-15px">
                {['Train', 'Active Learning'].map((s, idx) => (
                  <RadioButton key={idx} checked={selectedType === s} onClick={() => setSelectedType(s)} label={s} />
                ))}
              </div>
            )}
          </div>

          <div className="text-center mt-4 w-100">
            <Button
              onClick={scheduleTraining}
              disabled={!selectedCondition || !selectedSpecies || !selectedType || isQuerying[QueryName.UpdateConditionActiveLearning]}
              color="primary"
            >
              Schedule
              {isQuerying[QueryName.UpdateConditionActiveLearning] && <Spinner size="sm" className="ml-1" color="light" />}
            </Button>

            {selectedCondition && !selectedConditionEntity?.ml_config && (
              <p className="text--danger text-m mb-0 mt-2 mx-auto width-400px">Needs configuration. Please contact an engineer.</p>
            )}
          </div>
        </ModalBody>
      </Modal>

      <div className="mb-5">
        <h5 className="bold">Scheduled Trainings</h5>

        <div className="mb-3">
          {scheduledConditions.length > 0 ? (
            <div className="d-flex flex-column gap-10px width-fit-content min-width-400px">
              {scheduledConditions.map(({ condition, species, status }, idx) => (
                <div className="d-flex justify-content-between align-items-center" key={idx}>
                  <p className="mb-0 text-m mr-5">
                    {condition.display_name} {species} {typeForPriority(status).toLowerCase()}
                  </p>

                  <Button onClick={() => cancelTraining(condition.id, species)} size="sm" color="danger" outline>
                    Cancel
                  </Button>
                </div>
              ))}
            </div>
          ) : (
            <p className="mb-0">No scheduled trainings.</p>
          )}
        </div>

        <Button color="success" className="width-100px" onClick={() => setDisplayScheduleTrainingModal(true)}>
          Add
        </Button>
      </div>

      <div className="d-flex gap-50px">
        {['single-gpu', 'multi-gpu'].map((server) => {
          const status = server === 'single-gpu' ? singleGpuMlServerStatus : mlServerStatus
          const mlServerIsRunning = status === 'running'

          return (
            <div>
              <h5 className="bold mb-2">{capitalize(server).replace('gpu', 'GPU')} Server</h5>
              <p className="text-m">
                Status: <span className={`bold ${mlServerIsRunning ? 'text-success' : ''}`}>{status || 'offline'}</span>
              </p>

              <Button
                color={mlServerIsRunning ? 'danger' : 'success'}
                className="width-100px"
                onClick={() => dispatch(updateMLServerStatusAction(mlServerIsRunning, server))}
              >
                {mlServerIsRunning ? 'Stop' : 'Start'}
              </Button>
            </div>
          )
        })}
      </div>
    </Col>
  )
}
